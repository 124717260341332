var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{staticClass:"d-flex justify-start align-center",attrs:{"cols":"9"}},[_c('h2',[_vm._v("Setting Permission")])])],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-title',[_c('h5',[_vm._v("Filter")])]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"label":"Cari","outlined":"","dense":"","hide-details":true},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-select',{attrs:{"hide-details":true,"items":_vm.statuses,"outlined":"","dense":"","item-text":"name","item-value":"value","label":"Status"},model:{value:(_vm.selectedStatus),callback:function ($$v) {_vm.selectedStatus=$$v},expression:"selectedStatus"}})],1)],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary","small":"","depressed":""},on:{"click":_vm.getData}},[_vm._v("Filter")]),_c('v-btn',{staticClass:"ml-3",attrs:{"color":"primary","small":"","depressed":"","outlined":""},on:{"click":function($event){_vm.search = ''
            _vm.selectedStatus = ''
            _vm.getData()}}},[_vm._v("Reset")])],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.data,"options":_vm.options,"server-items-length":_vm.total,"loading":_vm.loadingTable,"items-per-page":_vm.limit,"footer-props":{
        'items-per-page-options': [5, 10, 50, 100],
      }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.status == 1 ? 'Aktif' : 'Tidak Aktif')+" ")]}},{key:"item.option",fn:function(ref){
      var item = ref.item;
return [(item.id != 1 && item.id != 2)?_c('v-btn',{attrs:{"color":"warning","x-small":"","to":("/permission-menu-admin/" + (item.id))}},[_vm._v("Permission")]):_vm._e()]}}],null,true)})],1),_c('v-snackbar',{attrs:{"timeout":_vm.timeout,"color":_vm.alert.success ? 'success' : 'error',"right":"","bottom":""},model:{value:(_vm.alert.status),callback:function ($$v) {_vm.$set(_vm.alert, "status", $$v)},expression:"alert.status"}},[_vm._v(" "+_vm._s(_vm.alert.message)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }